$jordyGreen: #bef1bc;
$jordyYellow: #f6edac;
$themeColor: black;

@mixin posCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin xs {
  @media (max-width: 374px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 550px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin ellipse1 {
  word-break: keep-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@mixin ellipse2 {
  word-break: keep-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@mixin ellipse3 {
  word-break: keep-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
