@import './mixin.scss';

* {
  box-sizing: border-box;
}

html,
body,
button,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

.App {
  font-size: 15px;
  line-height: 1.4;
  // padding: 20px;
}

.hide {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: #222;
}

button {
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

img {
  width: 100%;
  max-width: 100%;
}

main {
  min-height: 100vh;
  padding: 50px 0 0;
  font-size: 15px;
  user-select: none;

  .area-adsense {
    min-height: 100px;
    width: 100%;
    overflow: hidden;
    // border: 2px solid red;

    &.mt10 {
      margin-top: 10px;
    }

    &.mt15 {
      margin-top: 15px;
    }

    &.mtb10 {
      margin: 10px 0;
    }
  }

  // 중간 광고 우측이랑 같이 나올때 컨트롤
  .res-mid-ads {
    opacity: 0;
    height: 0;
    overflow: hidden;
    // margin-top: -12px;
    // display: none; //에러남

    @include lg {
      opacity: 1;
      height: auto;
      margin: 0px;
    }
  }

  .inside {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.Page-menu {
    .inside {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .sc-content {
    background-color: #fff;
    padding: 20px 15px 20px;
    text-align: left;

    +.sc-content {
      margin-top: 8px;
    }

    &.sc-wide {
      padding: 0;
      background: transparent;

      h3 {
        padding-left: 10px;
      }
    }

    .tit {
      font-size: 18px;
      margin-bottom: 5px;
    }

    strong {
      font-weight: bold;
    }

    .list-wrap {
      display: flex;
      flex-wrap: wrap;

      .list-group {
        width: calc(50% - 5px);
        background-color: #ecf0f3;
        border-radius: 10px;
        padding: 12px 15px;
        font-size: 14px;
        line-height: 1.5;

        strong {
          display: block;
          font-size: 16px;
          margin-bottom: 5px;
        }

        &:nth-child(even) {
          margin-left: 10px;
        }
      }

      .swiper-container {
        padding: 10px;
      }

      .swiper-slide {
        width: 32vw;
        min-width: 160px;
        max-width: 230px;
        background-color: #fff;
        box-shadow: 0 0 8px rgb(0 0 0 / 12%);

        &.list-group:nth-child(even) {
          margin: 0;
        }
      }
    }

    p {
      word-break: keep-all;
    }

    article {
      +article {
        margin-top: 20px;
      }

      &:last-child {
        p {
          max-width: calc(100% - 45px);
        }
      }
    }
  }

  .data-desc {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #bbb;
    padding: 90px 0;
    text-align: center;

    span {
      color: #999;
      display: block;
      margin-bottom: 5px;
    }

    svg {
      font-size: 40px;
      transform-origin: bottom center;
      animation: loadMotion2 0.4s infinite ease-in-out alternate;
    }

    @keyframes loadMotion2 {
      0% {
        transform: scale(0.85);
        opacity: 0.5;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  // 반응형 2단
  .flex-column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    >article,
    >section,
    >div,
    >ul {
      margin: 0;
      flex: 1;

      // 우측 광고 반응형
      min-width: calc(50% - 125px);

      @include lg {
        min-width: 50%;
      }

      +div,
      +article,
      +section {
        margin-left: 10px;

        @include md {
          margin-left: 0;
        }
      }
    }

    // 우측 광고 반응형
    .right-ads {
      // border: 1px solid red;
      flex: 1;
      min-width: 230px;
      margin-top: 10px;
      // border: 2px solid red;

      @include lg {
        // opacity: 0 !important;
        // width: 0 !important;
        // height: 0 !important;
        // overflow: hidden;
        // display: none;
      }
    }

    // 1단형 우측 광고 하나
    &.col1 {

      >article,
      >section,
      >div,
      >ul {
        // 우측 광고 반응형
        min-width: calc(100% - 240px);

        @include lg {
          min-width: 100%;
        }
      }

      .right-ads {
        margin-top: 0 !important;
        min-width: 230px !important;

        @include lg {
          display: block;
        }

        @include sm {
          // opacity: 0 !important;
          // width: 0 !important;
          // height: 0 !important;
          // overflow: hidden;
          // display: none;
        }
      }
    }

    @include md {
      flex-direction: column;

      >div+div,
      >section+section,
      >article+article {
        margin: 10px 0 0 0;
      }
    }
  }

  // 레이아웃
  .res-column {
    display: flex;
    justify-content: space-between;
    margin: 12px;

    >* {
      flex: 1;
      margin: 0 !important;

      +* {
        margin-left: 12px !important;
      }
    }

    @include lg {
      display: block;

      >* {
        +* {
          margin: 12px 0 0 0 !important;
        }
      }
    }
  }

  // 중간 배너 영역
  .mid-banner {
    margin: 8px 0;
    display: flex;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);

    &.lg a {
      padding: 20px 0;
      font-size: 18px;

      svg {
        font-size: 23px;
      }

      strong {
        font-size: 18px;

        color: #f16f74;
        margin: 0 5px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      background: #fff;
      // border-radius: 8px;
      padding: 20px 0;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.3px;
      border: 0;

      @include sm {
        padding: 15px 0;
        font-size: 15px;
      }

      @include xs {
        font-size: 12px;
      }

      +a {
        border-left: 1px solid #d2d7db;

        @include xs {
          flex: 1.2;
        }
      }

      svg {
        font-size: 22px;
        margin-right: 5px;
        color: #65c5c2;

        animation: svgMotion 0.8s infinite ease-out alternate;
      }

      @keyframes svgMotion {
        0% {
          transform: translateY(2px) scale(0.9);
          opacity: 0.7;
        }

        70% {
          opacity: 1;
          transform: translateY(0px) scale(1);
        }

        100% {
          opacity: 1;
          transform: translateY(0px) scale(1);
        }
      }

      &.ico-coin {
        svg {
          font-size: 20px;
          color: #f3ce45;
          margin-right: 3px;
        }
      }

      &.ico-menu {
        svg {
          color: #f16f74;
        }
      }

      &.ico-mint {
        svg {
          color: #65c5c2;
        }

        strong {
          color: #65c5c2;
        }
      }
    }
  }

  // 리스트스타일
  .list-type1 {
    margin: 10px 0;

    >li {
      margin-bottom: 5px;
    }

    &.mb10 {
      >li {
        margin-bottom: 10px;
      }
    }

    ul {
      margin: 5px 0;
      font-size: 13px;
      color: #666;
      padding-left: 5px;

      li {
        position: relative;
        padding-left: 8px;

        &::before {
          content: '';
          width: 3px;
          height: 3px;
          background-color: #aaa;
          display: inline-block;
          position: absolute;
          top: 7px;
          left: 0;
        }
      }
    }

    strong {
      font-weight: bold;
      font-size: 17px;
    }

    .list-desc {
      font-size: 14px;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 10px;
      border-radius: 5px;

      .cal {
        display: block;
        color: #666;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    li {
      position: relative;
      padding-left: 10px;

      +li {
        margin-top: 4px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background-color: black;
      }
    }
  }

  button {
    border: 3px solid #000;
    background-color: #fff;
    box-shadow: 1px 3px 0 rgba(0, 0, 0, 1);
    min-width: 75px;
    border-radius: 40px;
    padding: 6px 18px;
    font-size: 17px;
    font-weight: 700;
    color: #000;
    margin: 4px;

    &:focus {
      outline: none;
    }

    &:active {
      background-color: #eee;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
  }

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12px;
    transform: translateX(-3px);

    .h-sub {
      font-size: 15px;
      color: #555;
    }
  }

  h2 {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
  }

  h3 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  section {
    background-color: rgba(255, 255, 255, 1);
    padding: 12px;
    margin: 8px 0;
    border-radius: 8px;

    &.alL {
      text-align: left;
    }

    &.sc-pd {
      margin: 12px;
    }

    .desc {
      margin-bottom: 5px;
    }
  }

  article {
    +article {
      margin-top: 12px;
    }
  }

  .news-list {
    a {
      display: block;
      text-align: left;
      margin-bottom: 10px;

      .tit {
        font-weight: bold;
        color: #111;
        margin-bottom: 6px;
        @include ellipse2;
      }

      .desc {
        font-size: 16px;
        opacity: 0.85;
        margin: 0;
        @include ellipse2;

        @include md {
          font-size: 14px;
        }
      }

      .date {
        font-size: 11px;
        opacity: 0.5;

        @include md {
          font-size: 13px;
        }
      }
    }

    &.thumb-list {
      .swiper-slide {
        border-radius: 8px;
        overflow: hidden;
        padding: 0;

        max-width: 200px;
      }

      .img {
        height: 130px;
        overflow: hidden;

        @include md {
          height: 110px;
        }

        img {
          height: 100%;
        }
      }

      .cont {
        padding: 10px;

        strong {
          font-weight: 600;
          font-size: 16px;
          line-height: 1.3;
          @include ellipse2;

          @include md {
            font-size: 14px;
          }
        }

        p {
          margin-top: 5px;
          font-size: 15px;
          color: #555;
          @include ellipse3;

          @include md {
            font-size: 13px;
          }
        }
      }
    }
  }

  section.sw {
    .swiper-container {
      padding: 10px 12px;
    }

    .swiper-slide {
      background: #fff;
      height: auto;
      width: 42vw;
      max-width: 230px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 8px;

      .tit {
        font-size: 16px;
        @include ellipse2;

        @include md {
          font-size: 14px;
        }
      }

      .desc {
        font-size: 15px;
        @include ellipse3;

        @include sm {
          @include ellipse2;
        }

        @include md {
          font-size: 13px;
        }
      }

      .date {
        display: none;
      }
    }
  }

  .tb-type1 {
    overflow: hidden;
    overflow-x: auto;

    table {
      border-collapse: collapse;
      width: 100%;
      min-width: 700px;
      margin-top: 10px;
      border-top: 2px solid #000;

      @include sm {
        min-width: 500px;
      }

      th,
      td {
        padding: 6px 5px;
        border: 1px solid #ccc;
        text-align: center;

        // word-break: keep-all;
        vertical-align: middle;
        font-size: 13px;

        * {
          font-size: 13px;
        }
      }

      th {
        padding: 7px 5px;
        background: rgba(255, 255, 255, 0.9);
        font-weight: bold;
      }

      ul {
        text-align: left;

        li {
          position: relative;
          padding-left: 6px;

          +li {
            margin-top: 4px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 2px;
            height: 2px;
            border-radius: 2px;
            background-color: black;
          }
        }
      }
    }
  }

  .tab-list1 {
    text-align: left;
    margin-bottom: 10px;

    h3 {
      margin-bottom: 5px;
    }

    &.isfix {
      position: sticky;
      top: 50px;
      left: 0;
      width: 100%;
      z-index: 9;
      padding: 8px 8px;
      transform: translateY(-1px);
      background-color: #fff;
      margin-bottom: 0;
      border-bottom: 1px solid #eee;
      box-shadow: 0 5px 5px rgb(0 0 0 / 3%);
    }

    button {
      min-width: auto;
      font-size: 13px;
      padding: 8px 10px;
      // border: 1px solid #aaa;
      border: 0;
      background-color: #eceff1;
      box-shadow: none;
      margin: 3px 2px;
      font-weight: 600;

      &.on {
        font-weight: bold;
        background-color: #222;
        color: #fff;
        border-color: #000;
      }
    }
  }

  .Page-spin__list {
    overflow: hidden;

    >div {
      margin: 0 auto !important;
    }

    img {
      // filter: saturate(20px);
    }
  }

  .Page-spin__desc {
    margin-bottom: 10px;
    font-size: 16px;
    // min-height: 50px;

    >div,
    >p {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .result-info {
      font-size: 17px;

      &.on {
        .ready {
          display: block;
          animation: announce 0.08s infinite alternate;
        }
      }

      &.isResult {
        .result {
          display: block;
        }

        .ready {
          display: none;
        }
      }

      .ready {
        .ico {
          display: inline-block;
          font-size: 35px;
          line-height: 1;
          margin-left: 3px;
        }
      }

      .result {
        display: none;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;

        em {
          margin-left: 10px;
          font-size: 25px;
          font-weight: bold;
          display: inline-block;
          animation: popMotion 0.3s;
          box-shadow: inset 0 -10px rgba(rgb(56, 225, 255), 1),
            inset 0 -10px yellow;
        }
      }
    }

    .rate {
      color: #3b84d8;
    }

    .s-desc {
      font-size: 13px;
      opacity: 0.6;
      margin-bottom: 5px;
    }

    @keyframes announce {
      0% {
        transform: rotate(-10deg);
      }

      100% {
        transform: rotate(10deg);
      }
    }

    @keyframes popMotion {
      0% {
        opacity: 0;
        transform: scale(0);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .Page-spin__data {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    span {
      position: relative;
      display: inline-flex;
      align-items: center;
      // justify-content: center;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0 22px 0 0;
      font-size: 12px;
      min-width: 50px;

      +span {
        margin: 4px;
      }

      em {
        height: 100%;
        padding: 4px 5px 4px 7px;
        font-size: 13px;
        font-weight: 500;
      }

      button {
        position: absolute;
        right: 1px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        width: 20px;
        height: 20px;
        min-width: auto;
        padding: 5px;
        box-shadow: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border: 0;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }
  }

  .loading {
    z-index: 7;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    p {
      font-size: 25px;
      animation: loadMotion 0.5s infinite alternate;
    }

    @keyframes loadMotion {
      0% {
        transform: scale(0.85);
        opacity: 0.5;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .bottom-100 {
    margin-bottom: 5px;
  }

  .bottom-banner {
    display: flex;

    >div {
      text-align: right;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
        text-align: left;
      }
    }

    @include md {
      flex-direction: column;

      div {
        text-align: center !important;
        margin: 0;
      }

      >div:last-child {
        margin-top: 5px;
        margin-left: 0;
        display: none;
      }
    }
  }
}

.table-scroll-wrap {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  margin: 10px 0;

  &.min100 table {
    min-width: 100%;
  }

  &.min600 table {
    min-width: 600px;
  }

  table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;

    th,
    td {
      padding: 6px 8px;
      border: 1px solid #ccc;
      font-size: 14px;

      * {
        font-size: 14px;
      }
    }

    th {
      background-color: #eee;
      font-weight: 600;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.alR {
  text-align: right;
}

.btn-more-wrap {
  text-align: center;
}

.btn-more {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin: 5px 0 0;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #222;
  font-weight: bold;
  padding: 15px 8px;
  background-color: #f1f3f5;

  &:hover {
    color: #222;
  }

  svg {
    margin-left: 5px;
  }
}

.covid-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  background: #87bce2;
  // border-radius: 0 0 8px 8px;
  padding: 13px 0;
  color: #fff !important;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-weight: 600;
    color: #fff !important;
    font-size: 15px;
    letter-spacing: -0.4px;

    @include sm {
      font-size: 13px;
    }

    * {
      color: #fff !important;
    }

    +a {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  em {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    opacity: 0.6;
    font-size: 15px;

    svg {
      line-height: 1;
    }
  }
}

.move-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  article {
    width: 100%;
    padding: 20px;
    margin-top: -100px;
    max-width: 500px;
  }

  p {
    color: #444;
    line-height: 1.5;
    margin: 10px 0;
  }

  .bottom-desc {
    margin-top: 30px;
  }

  .btn-wrap {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #aaa;
    color: #000;
    border-radius: 8px;
    padding: 20px 30px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;

    svg {
      margin-left: 10px;
    }

    +a {
      margin-top: 20px;
    }
  }
}